/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Portolio() {
  return (
    <>
      <IndexNavbar fixed />
      

      
        
      
      <div data-aos="fade-in" className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">Portfolio</h2>
              </div>
              </div>
            </div>
          </div>
      </div>
      <section className=" pb-40 relative">
        {/* <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div> */}
        

        <div className="justify-center text-center flex flex-wrap mt-10">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <p data-aos="fade-up" className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">
            Qzaak always follows the best and most efficient software engineering methods. We have a dedicated management team specialized in
information technology to manage and make sure that the whole development process meets industry standards.
            </p>
          </div>
        </div>
      </section>

      <section className="block relative z-1 pb-40 bg-blueGray-600">
        <div className="container mx-auto">
          <div className="justify-center flex flex-wrap">
            <div className="w-full spt-10 lg:w-12/12 px-4  -mt-24">
            <div className="flex flex-wrap">
                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    Makan
                  </h5>
                  <a target="_blank"  href="https://apps.apple.com/in/app/makan/id1440471441">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/makan_app.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    Ephone Sales
                  </h5>
                  <a tartet="_blank" href="https://apps.apple.com/us/app/ephone-salesman/id1615942489">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/ephone_sales.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    Ephone
                  </h5>
                  <a target="_blank" href="https://apps.apple.com/in/app/ephone-plus/id1487771931">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/ephone.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    Makan Portal
                  </h5>
                  <a target="_blank" href={require("assets/img/works/makan.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/makan.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    Mechanics
                  </h5>
                  <a target="_blank" href={require("assets/img/works/mechanics.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/mechanics.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    Real Estate Souq
                  </h5>
                  <a target="_blank" href={require("assets/img/works/souq.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/souq.jpg").default}
                      />
                    </div>
                  </a>
                </div>
                

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Al Khalili Service call Manager
                  </h5>
                  <a target="_blank" href={require("assets/img/works/alkahlili_servicecall_manager.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/alkahlili_servicecall_manager.jpg").default}
                      />
                    </div>
                  </a>
                </div>
                

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    Makan ERP Attendance APP
                  </h5>
                  <a target="_blank" href="https://www.figma.com/proto/nTUMPhZsdoiA4V7nLmHoJx/Greeniit-Attendance?node-id=7%3A34&scaling=scale-down&page-id=0%3A1&starting-point-node-id=7%3A34">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/makanerp_attendance.jpg").default}
                      />
                    </div>
                  </a>
                </div>
                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Boom Burger Attendance APP
                  </h5>
                  <a target="_blank" href={require("assets/img/works/boomburger_app.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/boomburger_app.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  My Window Attendance APP
                  </h5>
                  <a target="_blank" href={require("assets/img/works/mywindow_attendance.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/mywindow_attendance.jpg").default}
                      />
                    </div>
                  </a>
                </div>

              
                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    GSS Training
                  </h5>
                  <a target="_blank" href="https://gss-training.com/">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/gss.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                    JP Energy
                  </h5>
                  <a target="_blank" href="https://www.jp-energy.com/">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/jp.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Al Khalili chatbot
                  </h5>
                  <a target="_blank" href="https://www.alkhalili.com/">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/alkhalili.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  City Shipping
                  </h5>
                  <a target="_blank" href="http://cityshippingoman.com/">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/city_shipping.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Matrix
                  </h5>
                  <a target="_blank" href="https://matrixdebtc.com/">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/matrix.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Tee Dee Technologies
                  </h5>
                  <a target="_blank" href="http://teedeetech.com/">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/teedee.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Guardian
                  </h5>
                  <a target="_blank" href="https://guardian-ers.com/">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/guardian.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Sofaland
                  </h5>
                  <a target="_blank" href="http://sofalandarabia.com/">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/sofaland.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Woodbine
                  </h5>
                  <a target="_blank" href="http://woodbinehotels.in/">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/woodbine.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Reliable
                  </h5>
                  <a target="_blank" href="https://reliableintllc.com/">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/reliable.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  IMD
                  </h5>
                  <a target="_blank" href="http://innovativemoderndesign.com/">
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/imd.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Neseqly
                  </h5>
                  <a target="_blank" href={require("assets/img/works/neseqly.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/neseqly.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Ephone Online POS with Multiple branches
                  </h5>
                  <a target="_blank" href={require("assets/img/works/ephone-pos.png").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/ephone-pos.png").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan PMS, HRM, ACCOUNTING
                  </h5>
                  <a target="_blank" href={require("assets/img/works/amp.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/amp.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan HR, ACCOUNTING, PMS
                  </h5>
                  <a target="_blank" href={require("assets/img/works/alfanar.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/alfanar.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Vehicle Management System
                  </h5>
                  <a target="_blank" href={require("assets/img/works/masrooq.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/masrooq.jpg").default}
                      />
                    </div>
                  </a>
                </div>
                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan HR,CRM,Construction,Accounting
                  </h5>
                  <a target="_blank" href={require("assets/img/works/ncl.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/ncl.jpg").default}
                      />
                    </div>
                  </a>
                </div>
                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan CRM,PMS,HRMS,Accounting
                  </h5>
                  <a target="_blank" href={require("assets/img/works/ncl_woodline.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/ncl_woodline.jpg").default}
                      />
                    </div>
                  </a>
                </div>
{/* 
                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Accounting, Construction
                  </h5>
                  <a target="_blank" href={require("assets/img/works/alasmi.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/alasmi.jpg").default}
                      />
                    </div>
                  </a>
                </div> */}

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Vehicle Management System
                  </h5>
                  <a target="_blank" href={require("assets/img/works/aat.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/aat.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan HRMS
                  </h5>
                  <a target="_blank" href={require("assets/img/works/boomburger_hr.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/boomburger_hr.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                {/* <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Accounting, Construction
                  </h5>
                  <a target="_blank" href={require("assets/img/works/dreamvilla.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/dreamvilla.jpg").default}
                      />
                    </div>
                  </a>
                </div> */}

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Debt, Employee Management
                  </h5>
                  <a target="_blank" href={require("assets/img/works/equity.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/equity.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan POS,Kitchen,HR,Accounting,Rent
                  </h5>
                  <a target="_blank" href={require("assets/img/works/gateaux.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/gateaux.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Hal Services 
                  </h5>
                  <a target="_blank" href={require("assets/img/works/hal.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/hal.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Debt Management,HR,Accounting
                  </h5>
                  <a target="_blank" href={require("assets/img/works/matrixerp.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/matrixerp.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                {/* <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Property Management
                  </h5>
                  <a target="_blank" href={require("assets/img/works/mc.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/mc.jpg").default}
                      />
                    </div>
                  </a>
                </div> */}

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan CRM,PMS,HRMS,Accounting
                  </h5>
                  <a target="_blank" href={require("assets/img/works/mywindow.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/mywindow.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan POS
                  </h5>
                  <a target="_blank" href={require("assets/img/works/naturehike.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/naturehike.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan POS
                  </h5>
                  <a target="_blank" href={require("assets/img/works/safeer.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/safeer.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                {/* <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Property Management
                  </h5>
                  <a target="_blank" href={require("assets/img/works/shadow_golden_desert.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/shadow_golden_desert.jpg").default}
                      />
                    </div>
                  </a>
                </div> */}

                {/* <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Property Management
                  </h5>
                  <a target="_blank" href={require("assets/img/works/sia_properties.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/sia_properties.jpg").default}
                      />
                    </div>
                  </a>
                </div> */}

                

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan CRM
                  </h5>
                  <a target="_blank" href={require("assets/img/works/real_estate_souq.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/real_estate_souq.jpg").default}
                      />
                    </div>
                  </a>
                </div>
                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Accounting,Construction
                  </h5>
                  <a target="_blank" href={require("assets/img/works/space_design.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/space_design.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Construction,Accounting,HR
                  </h5>
                  <a target="_blank" href={require("assets/img/works/tathmeer.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/tathmeer.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Construction,Accounting,CRM,POS
                  </h5>
                  <a target="_blank" href={require("assets/img/works/imd-erp.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/imd-erp.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan Property Management
                  </h5>
                  <a target="_blank" href={require("assets/img/works/altayar.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/altayar.jpg").default}
                      />
                    </div>
                  </a>
                </div>

                <div data-aos="zoom-out-up" className="w-full lg:w-4/12 px-4">
                  <h5 className="c-green text-xl font-semibold pb-4 text-center">
                  Makan POS,HR,Accounting,Production
                  </h5>
                  <a target="_blank" href={require("assets/img/works/erp_sofaland.jpg").default}>
                    <div className="hover:-mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg ease-linear transition-all duration-150">
                      <img
                        alt="..."
                        className="align-middle border-none max-w-full h-auto rounded-lg"
                        src={require("assets/img/works/erp_sofaland.jpg").default}
                      />
                    </div>
                  </a>
                </div>
                
                
                
              </div>
            </div>
          </div>
        </div>
      </section>
     

     
      <Footer />
    </>
  );
}

/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Mailer from "components/mailer.js";

export default function HRMS() {
  return (
    <>
      <IndexNavbar fixed />

      <div data-aos="fade-in" className="page-title-area">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="page-title-content">
                <h2 data-aos="zoom-out">Contact Us</h2>
              </div>
              </div>
            </div>
          </div>
        </div>
      <section className=" md:mt-10 pb-20 relative bg-blueGray-100">
       
        <div className="container mx-auto px-4 pb-20 pt-20">
          <div className="items-center flex flex-wrap">
            <div className="w-full md:w-6/12 ml-auto px-12 md:px-4">
              <div className="md:pr-12">
                <h3 data-aos="fade-up" className="text-3xl font-semibold">
                Where to find us
                </h3>
                <p  data-aos="fade-up" className="mt-4 text-lg leading-relaxed text-blueGray-500">
                Want to get in touch with us ? We would love to hear from you. Here is how you can reach us</p>
               <img data-aos="zoom-out-up" alt="..." className="max-w-full" src={require("assets/img/contact-img.svg").default} />
              </div>
            </div>

            <div className="w-full md:w-5/12 mr-auto px-4 pt-24 md:pt-0">
            <ul className="col-12 col-md-4 block-details ul-reset">
                        <li data-aos="zoom-out" className="items border-green">
                        <img alt="..." className="max-w-full  national-img" src={require("assets/img/taj-mahal.png").default} />
                            <h2 className="heading-2">INDIA</h2>
                            <a className="p-tb-10" href="tel:+919895668887"><i className="fa fa-phone"></i> +91 98956 68887</a>
                            <a href="https://goo.gl/maps/uo4RRjXdoU8USuLT8" className="link-page" target="blank"><p className="para">
                               Jail Road Cross, Puthiyara, Baramco Building<br/>
                                    Calicut ,Kerala,India</p></a>
                        </li>
                        <li data-aos="zoom-in" className="items border-red">
                        <img alt="..." className="max-w-full  national-img-oman" src={require("assets/img/oman.png").default} />
                            <h2 className="heading-2">OMAN</h2>
                            <a href="tel:+96896672722"><i className="fa fa-phone"></i> +968-96672722</a>
                            <a href="https://goo.gl/maps/uBFDyb5DWFH6YeB29" className="link-page" target="blank"><p className="para">
                            Muscat Grand Mall, Building 4, 5th floor,<br/>
                            Al Takween Business Center, Muscat,<br/>
                            Sultanate Of Oman.
                            </p></a>
                        </li>
                        
                        <a href="https://goo.gl/maps/8mr7fPPEkNo2eErp8" className="link-page" target="blank">
                            <p></p></a>
                            
                        
                    </ul>
            </div>
          </div>
        </div>
      </section>
      
        <section className="relative py-20">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
    
          <div className="container mx-auto px-4">
          <div className="contact-box">
            <div className="container">
              <div className="row">             
                <div data-aos="zoom-out-up" className="col-lg-4 col-md-6">
                  <div className="single-contact-box">
                    <i className="fa fa-envelope"></i>
                    <div className="content-title">
                      <h3>Email</h3>
                      <a href="mailto:info@qzaak.com"><p>enquiry@qzaak.com</p></a>
                    </div>
                  </div>
                </div>
                <div data-aos="zoom-out-up" className="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                  <div className="single-contact-box">
                    <i className="fa fa-phone"></i>
                    <div className="content-title">
                      <h3>Phone</h3>
                      <a href="tel:+96896672722"></a> <p>+91-9895668887</p>
                      <a href="tel:+96891382696"><p>+968-91382696</p></a> 
                    </div>
                  </div>
                </div>
                <div data-aos="zoom-out-up" className="col-lg-4 col-md-6">               
                  <div className="single-contact-box">
                    <i className="fas fa-clock"></i>
                    <div className="content-title">
                      <h3>Business Hours</h3>
                      <p>
                      Sunday - Thursday 9am to 6pm<br/>
                      Friday, Saturday - Closed
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
        
        <div className="contact-section ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div data-aos="zoom-out-up" className="col-lg-6"><div className="contact-image">
              <img
               alt="..."
               className="max-w-full" src={require("assets/img/contactus.png").default}
             />
                </div>
              </div>
              <div data-aos="zoom-out-up" className="col-lg-6">
              <Mailer />
              </div>
            </div>
          </div>
        </div>

       
     

      
 
  

      <section className="bg-blueGray-200 relative pt-20">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

      </section>
      <Footer />
    </>
  );
}

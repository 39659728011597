import React from "react";
import { Link } from "react-router-dom";
import { createPopper } from "@popperjs/core";

const IndexDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  return (
    <>
      <a
        className="hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        Solutions
      </a>
      
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 solutions scroll-on-mobile"
        }
      >
        <Link
          to="./crms"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Makan CRM
        </Link>
        <Link
          to="./hrms"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Makan HRMS
        </Link>
        <Link
          to="./accounting"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Makan Online Accounting
        </Link>
        <Link
          to="./pos"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Makan Online POS
        </Link>
        <Link
          to="./property"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Makan Property Management System
        </Link>
        <Link
          to="./pms"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Makan Project Management System
        </Link>
        <Link
          to="./hotel_management"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Makan Hotel Management System
        </Link>
        <Link
          to="./consignment"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Makan Consignment Management System
        </Link>
        <Link
          to="./clinic"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
         Makan Clinical Management System
        </Link>
        <Link
          to="./school_management_system"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
         Makan School Management System
        </Link>
        
        <Link
          to="./vehicle_maintenance"
          className="py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Makan Vehicle Management System
        </Link>
        
        <Link
          to="./courier"
          className="py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Makan logistics system
        </Link>
        
        <Link
          to="./inventory"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Inventory Management System
        </Link>
        <Link
          to="./Support_Management_System"
          className=" py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Support Management System
        </Link>
        
        
        
         {/* <div className="h-0 mx-4 my-2 border border-solid border-blueGray-100" />
       <span
          className={
            "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
          }
        >
          Desktop Application :
        </span>
        <Link
          to="/Billing"
          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Billing Software (Pos)
        </Link>
        <Link
          to="/time"
          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Time Attendance
        </Link>
        <Link
          to="/cheque"
          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Cheque printing Software
        </Link>
        <Link
          to="/production"
          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Production Software
        </Link>
        <Link
          to="/rbbill"
          className="text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
        >
          Restaurant & Bar Billing
        </Link> */}
      </div>
    </>
  );
};

export default IndexDropdown;


import emailjs from 'emailjs-com'
import Swal from 'sweetalert2'
const Mailer = () => {
    function sendEmail(e){
        e.preventDefault();
        
        emailjs.sendForm(
            'service_5fgzquf',
            'template_rafpa0j',
            e.target,'user_gykOUOG3s1fW14BTHljRX'
            ).then(res=>{
              Swal.fire({
                title: 'Thank You!',
                html: 'Your submission has been sent.',
                icon: 'success'
              })
                console.log(res);
            }).catch(err=> console.log(err));
    }
    return(
        <div className="contact-form">
                  <form onSubmit={sendEmail} className="form-inside-input" noValidate>
                    <div className="row">
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input type="text" name="name" id="name" className="form-control" placeholder="Name" required />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input type="email" name="user_email" id="email" className="form-control" placeholder="Email" required />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input type="text" name="phone_number"  id="phone_number" className="form-control" placeholder="Phone" required />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <input type="text" name="subject" id="subject" className="form-control" placeholder="Subject" required />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <textarea name="message" id="message" className="form-control" rows="6" placeholder="Your Message" required></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <button value="send" type="submit" className="submit-btn mt-2">Send Message!</button>
                      </div>
                    </div>
                  </form>
                </div>
        
    );
}

export default Mailer;


/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function CRMS() {
    return (
        <>
            <IndexNavbar fixed />

            <div data-aos="fade-in" className="page-title-area">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="page-title-content">
                                <h2 data-aos="zoom-out">Support Management System</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className=" md:mt-10 pb-20 relative bg-blueGray-100">

                <div className="container mx-auto px-4 pb-20 pt-20">
                    <div className="items-center flex flex-wrap">
                        <div className="w-full md:w-10/12 ml-auto px-12 md:px-4">
                            <div className="md:pr-12">
                                {/* <h3 data-aos="fade-up" className="text-3xl font-semibold text-center">
                Consignment Management System
                </h3> */}
                                <div data-aos="zoom-out-up" className="md:w-8/12 m-auto">
                                    <img
                                        alt="..."
                                        className="max-w-full"
                                        src={require("assets/img/makan_support_management_system.png").default}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

            <section className="relative py-20">
                <div
                    className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
                    style={{ transform: "translateZ(0)" }}
                >
                    <svg
                        className="absolute bottom-0 overflow-hidden"
                        xmlns="http://www.w3.org/2000/svg"
                        preserveAspectRatio="none"
                        version="1.1"
                        viewBox="0 0 2560 100"
                        x="0"
                        y="0"
                    >
                        <polygon
                            className="text-white fill-current"
                            points="2560 0 2560 100 0 100"
                        ></polygon>
                    </svg>
                </div>

                <div className="container mx-auto px-4">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                            <div className="md:pr-12">
                                <h3 data-aos="flip-down" className="text-3xl font-semibold">Contract Creation</h3>
                                <ul className="list-none mt-6">
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Contract type master
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Periodic maintenance to frequency
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Table autoloading based on frequency
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Frequency Alert before x days
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Call based maintenence
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Multiple upload option
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                View option in contract listing page
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Filter - Customer id, Ref no, Product segment, Contract type
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Export to pdf/print
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Utilization report based on frequency
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                SLA selection [non-standard]
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                            <div className="md:pr-12">

                                <h3 data-aos="flip-down" className="text-3xl font-semibold">Ticket Creation</h3>
                                <ul className="list-none mt-6">
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Ticket Creation
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Customer creation product segments SLA

                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Ticket listing add filter
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Inside ticket - Manager should have option to add additional email id of customer to alert.
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Rename company to customer and Customer to user
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Rearrange menus as per client requirement
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Attachment should not support any exe, cmd etc.. intallation files
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Engineer approval should be renamed to accept/ reject.
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Engineer acceptance should show to the customer and his contact details also should be listed.
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Each manager should see only his services and tickets.
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Assign tickets - should have priority in the system.
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                In email - subject - line should have Ticket number.
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Company/customer name filter in table required.
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Ticket approximate time to duration - rename
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Customer dashboard - Service contracts should have separate popup view.
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                Registration of user from admin
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                renewal alerts to all privilaged users
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">
                                                report filter updates
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                            <div className="md:pr-12">

                                <h3 data-aos="flip-down" className="text-3xl font-semibold">Feedback</h3>
                                <ul className="list-none mt-6">
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">

                                                Hide feedback from admin panel ticket timeline, only show overall rating with percentage
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">

                                                Feedback report add filter: Customer id, Ref_id, Segment, SE

                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                    <li data-aos="zoom-out" className="py-2">
                                        <div className="flex items-center">
                                            <div>
                                                <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-lightBlue-600 bg-lightBlue-200 mr-3">
                                                    <i class="fas fa-arrow-right"></i>
                                                </span>
                                            </div>
                                            <div>
                                                <h4 className="text-blueGray-500">

                                                Customer feedback form on email/sms link
                          </h4>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

         
            <Footer />
        </>
    );
}
